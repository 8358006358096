import { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import axiosInstance from "../../utils/axsioInstance";

interface AuthenticatedAvatarProps {
  url: string;
  [key: string]: any;
}

function AuthenticatedAvatar({ url ,...props}: AuthenticatedAvatarProps) {
  const [avatarSrc, setAvatarSrc] = useState("/public/placeholder.png");

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const response = await axiosInstance.get(url, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);

        setAvatarSrc(imageUrl);
      } catch (error) {
        console.error("Error fetching avatar:", error);
      }
    };

    fetchAvatar();
  }, [url]); // Fetch avatar whenever userId changes

  return <Avatar src={avatarSrc} {...props} />;
}

export default AuthenticatedAvatar;
