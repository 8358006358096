import { useEffect, useState } from "react";
import "./styles.css";
import Sidebar from "../../components/Sidebar";
import {
  Box,
  Button,
  FormControl,
  Input,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import * as MI from "@mui/icons-material";
import * as I from "../../components/Icons";
import axiosInstance from "../../utils/axsioInstance";
import config from '../../config.json';
import AuthenticatedImage from "../../components/Common/authImg";
import Skeleton from "react-loading-skeleton";
import styled from "@emotion/styled";
import { t } from "i18next";
export default function Banners() {
  const [selectedImages, setSelectedImages] = useState<any>([]);
  const [curSlide, setcurSlide] = useState("/images/placeholder.png");
  const [fetched, setFetched] = useState(false);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(config.URLS.list_banners);
        setSelectedImages(response.data); 
        setFetched(true);
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  
const handleImageSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
    
    
    const formData = new FormData();
    
    for (let i = 0; i < files.length; i++) {
      formData.append(`pictures[${i}]`, files[i]);
    }

    try {
      const response = await axiosInstance.post(config.URLS.add_banner, formData);
      setSelectedImages(response.data.pics);
    } catch (error) {
      console.error('Error uploading files:', error);

    }

   
  }
};


  const handleDeleteImage = async (uuid: string) => {
    
    const formData = new FormData();
    formData.append('uuid', uuid);
    
    try {
      const response = await axiosInstance.post(config.URLS.delete_banner, formData);
      setSelectedImages(response.data.pics);
    } catch (error) {
     

    }

   
  
  };
  return (
    <div className="banners page">
      <div className="row">
        <Sidebar />
        <div className="content flex">
          <div className="row main-header">
            <div className="page-title row gap-16 center">
              <I.Media />
              <h1>Mes bannières</h1>
            </div>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              
              <FormControl size="small" sx={{ display: "flex", alignItems: "flex-end" }}>
              <Input
                className="search-input"
                disableUnderline
                startAdornment={
                    <InputAdornment position="start">
                      <I.Search />
                    </InputAdornment>
                  }
                placeholder={t("products.search")}
               
              />
            </FormControl>
                <Stack className="dropzone" component="label">
                        <MI.Upload color="primary" />
                        <VisuallyHiddenInput
                     
                          type="file"
                          accept="image/*"
                          multiple
                          onChange={handleImageSelect}
                        />
                      </Stack>
            </Box>
                
          </div>
          <div className="divider-56"></div>

          <div className="column gap-24">
            <div className="row gap-24">
              <div className="flow-container bloc">
                <h2>Images du slider</h2>
                 <div className="zone">
                    <Stack
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Stack className="dropzone" component="label">
                        <MI.Upload color="primary" />
                        <VisuallyHiddenInput
                          multiple
                          type="file"
                          accept="image/*"
                          onChange={handleImageSelect}
                        />
                      </Stack>
                      <div className="tips">
                        Cliquez sur l'icone pour charger vos slides
                      </div>
                      <div className="subtitle">
                        format acceptée : JPG, PNG, 2 Mo MAX
                      </div>
                    </Stack>
                  </div>
                <div className="subtitle">
      
                </div>
                <div className="row gap-8 img-preview">

                {selectedImages.map((image: any, index: number) => (
                        <div onClick={()=>{setcurSlide(config.URLS.banner_image+image?.banner_pic)}} className="img flex">
                          <AuthenticatedImage  
                          width="50px"
                            alt="banner"
                          url={config.URLS.banner_image+image?.banner_pic}
                          />
                    
                    <MI.Cancel  onClick={() => handleDeleteImage(image?.banner_uuid)}  className="icon-delete" />
                  </div>
                     ))}
             { !fetched &&( 
                  <>
                    <div  className="img flex"><Skeleton width={200} height={100} /></div>
                    <div  className="img flex"><Skeleton width={200} height={100} /></div>
                    <div  className="img flex"><Skeleton width={200} height={100} /></div>
                  </>
                )}
                                  
                  
                
                </div>
    
              </div>

              <div className="bloc phone-preview">
                <div className="mobile-header">
                  <I.Burger />
                  <I.Montura />
                  <I.Profile />
                </div>
                <div className="carousel-preview">
                  <AuthenticatedImage
                    width="100%"
                    alt="mm"
                    url={curSlide}
                  />
                </div>
                <div className="wrapper">
                  <h2>Marque</h2>
                  <Stack direction="row" spacing={2}>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                    <div className="placeholder"></div>
                  </Stack>
                  <h2>Article en vedette</h2>
                  <Stack direction="row" spacing={2}>
                    <div className="placeholder square"></div>
                    <div className="placeholder square"></div>
                    <div className="placeholder square"></div>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
