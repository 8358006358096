import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { theme } from "./theme/theme";

import common_fr from "./locales/fr.json";
import { Toaster } from "react-hot-toast";
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "fr", // language to use
  resources: {
    fr: {
      common: common_fr, // 'common' is our custom namespace
    },
  },
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Toaster />
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
