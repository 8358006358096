import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    mobile: true;
    tablet: true;
    md: true;
    lg: true;
    xl: false;
  }
}
const theme = createTheme({
  typography: {
    fontSize: 16,
  },
  palette: {
    primary: {
      main: "#962DFF",
    },
  },

  breakpoints: {
    values: {
      mobile: 320,
      tablet: 480,
      md: 700,
      lg: 1024,
    },
  },
});

if (theme.components) {
  theme.components.MuiButton = {
    variants: [
      {
        props: { variant: "contained" },
        style: {
          borderRadius: 100,
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "#000000",
          },
        },
      },
      {
        props: { variant: "outlined" },
        style: {
          borderRadius: 100,
          textTransform: "capitalize",
          "&:hover": {},
        },
      },
    ],
    styleOverrides: {
      root: {
        fontFamily: "Barlow",
        [theme.breakpoints.down("mobile")]: {},
        [theme.breakpoints.between("mobile", "tablet")]: {},
        [theme.breakpoints.up("tablet")]: {},
      },
    },
  };
}

export { theme };
