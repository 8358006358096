import { useState, useEffect } from "react";
import axiosInstance from "../../utils/axsioInstance";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

interface AuthenticatedImageProps {
  url: string;
  [key: string]: any;
}
function AuthenticatedImage({ url, ...props }: AuthenticatedImageProps) {
  const [avatarSrc, setAvatarSrc] = useState("/images/placeholder.png");
  const [loaded,setLoaded] = useState(false);
  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        const response = await axiosInstance.get(url, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(blob);

        setAvatarSrc(imageUrl);
        setLoaded(true);
      } catch (error) {
        console.error("Error fetching avatar:", error);
      }
    };

    fetchAvatar();
  }, [url]);

  return (<img src={avatarSrc} {...props} />);
}

export default AuthenticatedImage;
