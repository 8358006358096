
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Protected from './utils/protected';
import Dashboard from './pages/dashboard';
import UnProtected from './utils/unprotected';
import LoginPage from './pages/login';
import Products from './pages/products';
import { GlobalProvider } from './utils/globalContext';
import Orders from './pages/orders';
import Banners from './pages/banners';
import Settings  from './pages/settings';
import ResetPassword from './pages/resetPassword';
import ChangePassword from './pages/changepassword';

function App() {
  return (
    <GlobalProvider>
    <BrowserRouter>
    <Routes >
      <Route path="/"  element={ <Protected><Dashboard /></Protected>} />
      <Route path="/login" element={  <UnProtected><LoginPage /></UnProtected>} />
      <Route path="/dashboard" element={  <Protected><Dashboard /></Protected>}/>
      <Route path="/products" element={  <Protected><Products /></Protected>}/>
      <Route path="/orders" element={  <Protected><Orders /></Protected>}/>
      <Route path="/banners" element={  <Protected><Banners /></Protected>}/>
      <Route path="/settings" element={  <Protected><Settings /></Protected>}/>
      <Route path="/resetPassword" element={  <UnProtected><ResetPassword /></UnProtected>}/>
      <Route path="/do-reset-password/:userID" element={  <UnProtected><ChangePassword /></UnProtected>}/>
      
    </Routes >
</BrowserRouter>
</GlobalProvider>
  );
}

export default App;
