import { useEffect, useState } from "react";
import "./styles.css";
import Sidebar from "../../components/Sidebar";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as MI from "@mui/icons-material";
import * as I from "../../components/Icons";
import {
  DataGrid,
  GridActionsCellItem,
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid";
import { toast } from "react-hot-toast";
import axiosInstance from "../../utils/axsioInstance";
import renderStars from "../../components/Products/ratingStars";
import AuthenticatedAvatar from "../../components/Common/authAvatar";
import AddProductModal from "../../components/Products/addProduct";
import { useTranslation } from "react-i18next";
import ProductPreview from "../../components/Products/productPreview";
import NoData from "../../animations/no-data.json";
import Lottie from 'lottie-react';
import config from '../../config.json';
import LoadingDialog from "../../components/Common/loadingDialog";
import debounce from "lodash/debounce";




export default function Products() {
  const { t } = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 5 });
  const [addOpen, setaddOpen] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [search, setSearch] = useState("");
  const [working, setWorking] = useState(false);
  const [paramsData, setParamsData] = useState<any>(null);
  const [selectedProduct, setselectedProduct] = useState(null);
  const [disponibility, setDisponibility] = useState<any>("");
   const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: any}>({
    "brand": {value : "" ,label : "Marque",data : "brands"},
    "wearer": {value : "" ,label : "Porteur",data : "wearers"},
    "gamme":  {value : "" ,label : "Gamme",data : "gammes"},
    "style":  {value : "" ,label : "Style",data : "styles"},
    "form":  {value : "" ,label : "Forme",data : "forms"},
    "material":  {value : "" ,label : "Material",data : "materials"},
    "assembly":  {value : "" ,label : "Montage",data : "assemblies"},
  });
  
const columns: GridColDef[] = [
  {
    field: "product_model",
    headerName: "Article",
    flex: 1,
    cellClassName: "centered-content",
    renderCell: (params: GridCellParams) => (
      <div className="row center gap-16" style={{ position:"relative"}}>

      

        <AuthenticatedAvatar url={config.URLS.product_image+ params.row.pic} />
        <div className="rating column flex">
          <div className="item-name">{params.row.product_model}</div>
          <div className="stars row gap-4">
            {renderStars(params.row.rating)}
          </div>
        </div>
      </div>
    ),
  },
  {
    field: "product_reference",
    headerName: "Réference",
    flex: 1,
    cellClassName: "centered-content",
  },
  {
    field: "brand_id",
    renderCell: (params: GridCellParams) => (
      <div>{params.row.brand.brand_name}</div>
    ),
    headerName: "Marque",
    flex: 1,
    cellClassName: "centered-content",
  },
  {
    field: "disponibility",
    headerName: "Disponibilité",
    renderCell: (params: GridCellParams) => (
      <div className="disponibility row center">
        <div className={`left ${params.row.disponibility === 1 ? '' : 'no'}`}>
          <div>
            {params.row.disponibility==1?t('common.yes'):t('common.no')}
          </div>
            <div className="day">{params.row.delai_livraison}j</div>
        </div>
        
        {params.row.featured==1 &&  (<MI.Star style={{color:'#9747FF'}} />)}
        
       
        </div>
    ),
    flex: 1,
    cellClassName: "centered-content",
  },
  {
    field: "prix_vente",
    headerName: "Prix de vente",
    flex: 1,
    cellClassName: "centered-content",
  },
  {
    field: "prix_conseille",
    headerName: "Prix conseillé",
    flex: 1,
    cellClassName: "centered-content",
  },
];

  const debouncedSearch = debounce((searchValue) => {
    setSearch(searchValue); 
    setWorking(true);setRefresh(true);
  }, 1000);



  useEffect(() => {
    axiosInstance
      .get(config.URLS.product_params)
      .then((response) => {

        setParamsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
    const fetchData = async ( ) => {
     
      try {
         const formData = new FormData();
          for (const key in selectedFilters) {
            if (selectedFilters.hasOwnProperty(key)) {
              formData.append(key, selectedFilters[key].value);
            }
          }
          formData.append('search_term', search ?? "");
          formData.append('disponibility', disponibility ?? "");
        const response = await axiosInstance.post(config.URLS.list_products, formData);
        setRows(response.data.data);
        setPagination({
          page: response.data.current_page,
          pageSize: response.data.per_page,
        });
        setDataLoaded(true);
        setRefresh(false);
        setselectedProduct(null);
        setWorking(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  useEffect(() => {
     if(!refresh)
          return;
        fetchData();
  }, [refresh]);

  const openDialog = () => {
    setaddOpen(true);
  };


const renderFilter = (filterKey: string, filterLabel: string, filterData: string) => (
    <FormControl variant="filled" sx={{ m: 1, minWidth: 140 }} key={filterKey}>
      <InputLabel id={`lbl-${filterKey}`}>{filterLabel}</InputLabel>
      <Select
        className="select"
        id={`select-filled-${filterKey}`}
        size="small"
        disableUnderline
        value={selectedFilters[filterKey].value}
        onChange={(event) => {
      
          setWorking(true);
          setSelectedFilters({
            ...selectedFilters,
            [filterKey]:{"data":filterData,"value" : event.target.value,"label":filterLabel},
          });
        
          setRefresh(true);
        }}
      >
        <MenuItem value="">
          <em>{t("common.clear")}</em>
        </MenuItem>
        {paramsData != null &&
          paramsData[filterData].map((option: any, index: number) => (
            <MenuItem key={option[`${filterKey}_id`]} value={option[`${filterKey}_id`]}>
              {option[`${filterKey}_name`]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
 const filterElements = Object.keys(selectedFilters).map((key) => {
    const filterKey = key as string;
    const filterLabel = selectedFilters[key].label;
    const filterData = selectedFilters[key].data;
   
    return renderFilter(filterKey,filterLabel,filterData);
  });
  return (
    <div className="page products">
      <div className="row">
        <Sidebar isItemSelected={selectedProduct != null}  />
        <div className="content flex">
          <div className="row main-header">
            <div className="page-title row gap-16 center">
              <I.Product />
              <h1>Articles</h1>
            </div>
            <FormControl size="small" sx={{ display: "flex", alignItems: "flex-end" }}>
              <Input
                className="search-input"
                disableUnderline
                startAdornment={
                    <InputAdornment position="start">
                      <I.Search />
                    </InputAdornment>
                  }
                placeholder={t("products.search")}
                onChange={(event)=>{ debouncedSearch(event.target.value);}}
              />
            </FormControl>

            <Button
            disableElevation
              variant="contained"
              startIcon={<MI.Add />}
              onClick={openDialog}
            >
              {t("products.add_product")}
            </Button>
          </div>
          <div className="divider-56"></div>

          <div className="column gap-24">
            <div className="row gap-24">
              <div className="inner-container">
                
                <div className="data-grid-container bloc flex">
                  <div className="filter-bar">

                   
                         <FormControl variant="filled" sx={{ m: 1, minWidth: 140 }}>
                          <InputLabel id="lbl1">Stock</InputLabel>
                          <Select
                            className="select"
                            id="select-filled"
                            size="small"
                            disableUnderline
                             onChange={(event) => {
                              setWorking(true);
                              setDisponibility(event.target.value);
                              setRefresh(true);
                            }}
                          >
                              <MenuItem value="">
                                <em>Tout</em>
                              </MenuItem>
                              <MenuItem value={"true"}>Disponible</MenuItem>
                              <MenuItem value={"false"}>Indisponible</MenuItem>
                          </Select>
                        </FormControl>
                       {filterElements}
                         
                  </div>
                    {(rows.length<=0)?
                  ( <div className="bloc no-data">
                      <Lottie animationData={NoData}  style={{ height: 160 }} />
                      <h1>{ dataLoaded?t('products.no_data'):t('common.loading')}</h1>
                    </div>):
               ( 
                  <DataGrid
                    getRowHeight={() => "auto"}
                    autosizeOnMount
                    
                     checkboxSelection
                    rows={rows}
                    columns={columns}
                    onRowClick={(
                      params: GridRowParams,
                      event: MuiEvent,
                      details: GridCallbackDetails,
                    ) => {
                      setselectedProduct(params.row);
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: pagination,
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                  />
                )}
                </div>
              </div>

              {selectedProduct && (
                <ProductPreview paramsData={paramsData} productData={selectedProduct} setRefresh={setRefresh} setProduct={setselectedProduct} />
              )}
            </div>
          </div>
        </div>
      </div>
      <AddProductModal paramsData={paramsData} open={addOpen} setRefresh={setRefresh} setOpen={setaddOpen} />
    <LoadingDialog open={working} />
    </div>
  );
}
