import { useState, useEffect } from "react";
import "./addUser.css";
import LoadingDialog from "../../Common/loadingDialog";

import { styled } from "@mui/material/styles";
import { color } from "@mui/system";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { toast } from "react-hot-toast";
import StepLabel from "@mui/material/StepLabel";
import axiosInstance from "../../../utils/axsioInstance";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import * as MI from "@mui/icons-material";
import * as I from "../../Icons";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import config from "../../../config.json";
import AuthenticatedImage from "../../Common/authImg";

function AddUser({
  open,
  setRefresh,
  setOpen,
  prefix
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  prefix:string;
}) {
  const { t } = useTranslation("common");

  const [working, setWorking] = useState(false);
  
  const handleNext = async () => {
    
      const isValid = await trigger();
     
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    
    
  };


  const closeDialog = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    getValues,
    reset,
  } = useForm();


  const onSubmit = async (data: any) => {
     setWorking(true);

    try{
       
      const response = await axiosInstance.post(config.URLS.user_add, data);

        if(response.data.success)
        {
           toast.success(t(response.data.message));
           setRefresh(true);
        }else{
           toast.error(t(response.data.message));
        }
      
    }
      catch(error) {
         toast.error(t("common.request_error"));
      }finally{
        setWorking(false);
        setOpen(false);
      }
  };
  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

     
  return (
    <>
        <Dialog
      className="user-dialog"
      open={open}
      onClose={closeDialog}
      fullWidth={true}
      PaperProps={{
        sx: {
          width: 1100,
        },
      }}
    >
      <DialogContent className="step-wizard">
        <form onSubmit={handleSubmit(onSubmit)}>
        
            <div className="step">
              <div className="step-header">
                <h2>{t("settings.user_infos")}</h2>
              </div>

              <Stack direction="row" spacing={2}>
         

                <div className="form">
                  <TextField
                    className="text-field"
                    {...register("username", { required: true })}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{prefix}</InputAdornment>,
                      }}
                    error={!!errors.username}
                    helperText={errors.username ? t("login.required_field") : ""}
                    label={t("login.username") }
                    variant="outlined"
                  />

                  <TextField
                    className="text-field"
                    {...register("password", { required: true })}
                    
                    error={!!errors.password}
                    helperText={
                      errors.password ? t("login.required_field") : ""
                    }
                    label={t("login.password") }
                    variant="outlined"
                  />
                  <TextField
                    className="text-field"
                    {...register("lname", { required: true })}
                    
                    error={!!errors.lname}
                    helperText={errors.lname ? t("login.required_field") : ""}
                    label={t("settings.last_name") }
                    variant="outlined"
                  />

                  <TextField
                    className="text-field"
                    {...register("fname", { required: true })}
                    
                    
                    error={!!errors.fname}
                    helperText={
                      errors.fname ? t("login.required_field") : ""
                    }
                    label={t("settings.first_name") }
                    variant="outlined"
                  />

                  <TextField
                    className="text-field"
                    {...register("email", {
                      required: true,
                            pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: t('common.email_invalid'),
                          },
                    })}
                    
                    error={!!errors.email}
                    helperText={
                      errors.email ? t("login.required_field") : ""
                    }
                    label={t('settings.email')}
                    variant="outlined"
                  />

                  <TextField
                    className="text-field"
                    {...register("tel", {
                       pattern: {
                            value:/(\+)?(\(?\d+\)?)(([\s-]+)?(\d+)){0,}/,
                            message: t('common.phone_invalid'),
                          },
                    })}
                      
                    error={!!errors.tel}
                    helperText={
                      errors.tel ? t("login.required_field") : ""
                    }
                    label={t('settings.tel')}
                    variant="outlined"
                  />

                  
                  <FormControlLabel
                    
                    control={<Switch {...register("admin")}    />}
                    label={t('common.admin')}
                  />
               
                </div>
              </Stack>
            </div>
        
        </form>
      </DialogContent>

      <Stack direction="row" className="dialog-actions">
     
        <div className="row btn-actions gap-24">

          <Button  variant="outlined" onClick={()=>{setOpen(false)}} >
              {t("common.cancel")}
            </Button>
          
          
          <Button variant="contained" onClick={handleNext}>
          
              {t("common.add")}
          </Button>
        </div>
      </Stack>
    </Dialog>
    <LoadingDialog open={working} />
    </>
  );
}

export default AddUser;
