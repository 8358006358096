import React from "react";
import "./styles.css";
import Lottie from "lottie-react";
import Loading from "../../animations/loading.json";

export default function SplashScreen() {
  return (
    <div className="splash">
      <Lottie animationData={Loading} style={{ height: 160 }} />
    </div>
  );
}
