import axios from "axios";
import config from "../config.json";


const axiosInstance = axios.create({
  baseURL: config.URLS.main_URL,
});
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("user-token");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default axiosInstance;
