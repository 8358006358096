import { useState, useEffect } from "react";
import "./editProduct.css";
import LoadingDialog from "../../Common/loadingDialog";

import { styled } from "@mui/material/styles";
import { color } from "@mui/system";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { toast } from "react-hot-toast";
import StepLabel from "@mui/material/StepLabel";
import axiosInstance from "../../../utils/axsioInstance";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import * as MI from "@mui/icons-material";
import * as I from "../../Icons";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import config from "../../../config.json";
import AuthenticatedImage from "../../Common/authImg";

function EditProductModal({
  product,
  open,
  paramsData,
  setRefresh,
  setOpen,
}: {
  product :any;
  open: boolean;
  paramsData:any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation("common");
  const validateDecimal = (value: string) => {
    const regex = /^\d+(\.\d{1,2})?$/;
    return (
      regex.test(value) ||
      "Must be a decimal number with up to two decimal places"
    );
  };

  const [selectedImages, setSelectedImages] = useState<any>([]);
  const [working, setWorking] = useState(false);
   

const handleImageSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
    
    
    const formData = new FormData();
    
    for (let i = 0; i < files.length; i++) {
      formData.append(`pictures[${i}]`, files[i]);
    }
    
  formData.append('product',product.id);
    try {
      const response = await axiosInstance.post(config.URLS.add_pic_product, formData);
      setSelectedImages(response.data.pics);
    } catch (error) {
      console.error('Error uploading files:', error);

    }
    finally{

    }

   
  }
};

  const handleDeleteImage = async (index: number) => {

   
    
    const formData = new FormData();
    formData.append('picture', selectedImages[index]?.pic_uuid);
    formData.append('product',product.id);
    
    try {
      const response = await axiosInstance.post(config.URLS.delete_pic_product, formData);
      setSelectedImages(response.data.pics);
    } catch (error) {
      console.error('Error uploading files:', error);

    }

   
  
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const steps = ["", "", ""];

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [messageOpen, setMessageOpen] = useState(false);
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  const handleNext = async () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep == 0) {
      const res = await trigger([
        "model",
        "reference",
        "prix_achat",
        "prix_vente",
        "prix_conseille",
        "delai_livraison",
        "description",
      ]);

      setMessageOpen(false);

      if (selectedImages.length <= 0) {
        setMessageOpen(true);
      }
      
      if (!res || selectedImages.length <= 0) {
        return;
      }
    }
    if (activeStep == 1) {
      const res = await trigger([
        "bridge",
        "length",
        "height",
        "width",
        "gamme",
        "brand",
        "assembly",
        "material",
        "wearer",
        "style",
        "form",
      ]);
      if (!res) {
        return;
      }
       
      const isValid = await trigger();
     
      if (isValid) {
        handleSubmit(onSubmit)();
      }
    }
    if(activeStep==2){
      setOpen(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = () => {};

  const closeDialog = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
    getValues,
    reset,
  } = useForm();


  const onSubmit = async (data: any) => {
     setWorking(true);
    const formData = new FormData();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    formData.append('product', product?.id);
    try{
       
      const response = await axiosInstance.post(config.URLS.edit_product, formData);

        if(response.data.success)
        {
           toast.success(t(response.data.message));
           setRefresh(true);
        }else{
           toast.error(t(response.data.message));
        }
      
    }
      catch(error) {
         toast.error(t("common.request_error"));
      }finally{
        setWorking(false);
      }
  };
  useEffect(() => {
    if (open) {
      reset();
      setActiveStep(0);
      setSelectedImages(product.product_pics);
    }
  }, [open, reset]);

     
  return (
    <>
        <Dialog
      className="product-dialog"
      open={open}
      onClose={closeDialog}
      fullWidth={true}
      PaperProps={{
        sx: {
          width: 1100,
        },
      }}
    >
      <DialogContent className="step-wizard">
        <form onSubmit={handleSubmit(onSubmit)}>
          {activeStep === 0 && (
            <div className="step">
              <div className="step-header">
                <h2>{t("products.infos")}</h2>
                <div className="subtitle">{t("products.complete")}</div>
              </div>

              <Stack direction="row" spacing={2}>
                <div className="uploader">
                  <div className="zone">
                    <Stack
                      spacing={2}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Stack className="dropzone" component="label">
                        <MI.Upload color="primary" />
                        <VisuallyHiddenInput
                          multiple
                          type="file"
                          accept="image/*"
                          onChange={handleImageSelect}
                        />
                      </Stack>
                      <div className="tips">
                        Cliquez sur l'icone pour charger vos photos
                      </div>
                      <div className="subtitle">
                        format acceptée : JPG, PNG, 2 Mo MAX
                      </div>
                    </Stack>
                  </div>
                  <div
                    style={{
                      color: "red",
                      marginTop: 10,
                      marginBottom: 10,
                      textAlign: "center",
                      width: "100%",
                      display: messageOpen ? "block" : "none",
                    }}
                  >
                    {t("products.pictures")}
                  </div>
                  <div className="row gap-8 img-preview">
                    {selectedImages.map((image: any, index: number) => (
                      <div className="img flex" key={index}>
                        <AuthenticatedImage url={"/product/image/" + image.pic_path}  width="50px" />
                        <MI.Cancel
                          className="icon-delete"
                          onClick={() => handleDeleteImage(index)}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="form">
                  <TextField
                    className="text-field"
                    {...register("model", { required: true })}
                    defaultValue={product?.product_model}
                    error={!!errors.model}
                    helperText={errors.model ? t("login.required_field") : ""}
                    label="Modèle"
                    variant="outlined"
                  />

                  <TextField
                    className="text-field"
                    {...register("reference", { required: true })}
                    
                     defaultValue={product?.product_reference}
                    error={!!errors.reference}
                    helperText={
                      errors.reference ? t("login.required_field") : ""
                    }
                    label="Référence"
                    variant="outlined"
                  />

                  <TextField
                    className="text-field"
                    {...register("prix_achat", {
                      required: true,
                      validate: validateDecimal,
                    })}
                      defaultValue={product?.prix_achat}
                    error={!!errors.prix_achat}
                    helperText={
                      errors.prix_achat ? t("login.required_field") : ""
                    }
                    label="Prix d'achat"
                    variant="outlined"
                  />

                  <TextField
                    className="text-field"
                    {...register("prix_vente", {
                      required: true,
                      validate: validateDecimal,
                    })}
                      defaultValue={product?.prix_vente}
                    error={!!errors.prix_vente}
                    helperText={
                      errors.prix_vente ? t("login.required_field") : ""
                    }
                    label="Prix de vente"
                    variant="outlined"
                  />

                  <TextField
                    className="text-field"
                    {...register("prix_conseille", {
                      required: true,
                      validate: validateDecimal,
                    })}
                      defaultValue={product?.prix_conseille}
                    error={!!errors.prix_conseille}
                    helperText={
                      errors.prix_conseille ? t("login.required_field") : ""
                    }
                    label="Prix conseillé"
                    variant="outlined"
                  />

                  <TextField
                    className="text-field"
                    {...register("delai_livraison", {
                      required: true,
                      validate: validateDecimal,
                    })}
                      defaultValue={product?.delai_livraison}
                    error={!!errors.delai_livraison}
                    helperText={
                      errors.delai_livraison ? t("login.required_field") : ""
                    }
                    label="Délai de livraison"
                    variant="outlined"
                  />

                  <TextField
                    className="text-field"
                    {...register("remise")}
                     defaultValue={product?.remise}
                    label="remise"
                    variant="outlined"
                  />

                  <TextField
                    fullWidth
                    {...register("description", { required: true })}
                    error={!!errors.description}
                    helperText={
                      errors.description ? t("login.required_field") : ""
                    }
                      defaultValue={product?.description}
                    id="outlined-textarea"
                    label="Description"
                    placeholder="Max 250 Mots..."
                    multiline
                    rows={4}
                  />
                  <FormControlLabel
                    required
                  
                    control={<Switch {...register("disponibility")}   defaultChecked={product?.disponibility==1?true:false} />}
                    label="En stock"
                  />
                  <FormControlLabel
                    required
                   
                    control={<Switch {...register("featured")} defaultChecked={product?.featured==1?true:false} />}
                    label="En vedette"
                  />
                </div>
              </Stack>
            </div>
          )}

          {activeStep === 1 && (
            <div className="step">
              <div className="step">
                <div className="step-header">
                  <h2>Information de base</h2>
                  <div className="subtitle">
                    Commencez par ajouter des photos en haute résolution
                  </div>
                </div>

                <Stack direction="row" spacing={2} alignItems={"flex-start"}>
                  <div className="mesure-parent">
                    <div className="box hauteur">
                      <I.GlassH />
                      <TextField
                        className="text-field"
                        {...register("height", { required: true })}
                        defaultValue={product?.height}
                        error={!!errors.height}
                        helperText={
                          errors.height ? t("login.required_field") : ""
                        }
                        label="Hauteur (mm)"
                        variant="outlined"
                      />
                    </div>
                    <div className="box largeur">
                      <I.GlassW />
                      <TextField
                        className="text-field"
                        defaultValue={product?.width}
                        {...register("width", { required: true })}
                        error={!!errors.width}
                        helperText={
                          errors.width ? t("login.required_field") : ""
                        }
                        label="Largeur (mm)"
                        variant="outlined"
                      />
                    </div>
                    <div className="box pont">
                      <I.GlassBr />
                      <TextField
                        className="text-field"
                        {...register("bridge", { required: true })}
                        error={!!errors.bridge}
                        defaultValue={product?.bridge}
                        helperText={
                          errors.bridge ? t("login.required_field") : ""
                        }
                        label="Pont (mm)"
                        variant="outlined"
                      />
                    </div>
                    <div className="box branche">
                      <I.GlassB />
                      <TextField
                        className="text-field"
                        defaultValue={product?.length}
                        {...register("length", { required: true })}
                        error={!!errors.length}
                        helperText={
                          errors.length ? t("login.required_field") : ""
                        }
                        label="Branche (mm)"
                        variant="outlined"
                      />
                    </div>
                  </div>

                  <div className="form">
                    <FormControl className="text-field">
                      <InputLabel id="marque-label">Marque</InputLabel>
                      <Select
                        labelId="marque-label"
                        className=" select"
                        {...register("brand", { required: true })}
                        error={!!errors.brand}
                        defaultValue={product.brand_id}
                        label="Marque"
                        onChange={handleChange}
                      >
                        {paramsData.brands &&
                          paramsData.brands.map((brand: any) => (
                            <MenuItem
                              key={brand.brand_uuid}
                              value={brand.brand_id}
                            >
                              {brand.brand_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl className="text-field">
                      <InputLabel id="gamme-label">Gamme</InputLabel>
                      <Select
                        labelId="gamme-label"
                        className=" select"
                        {...register("gamme", { required: true })}
                        error={!!errors.gamme}
                        defaultValue={product?.gamme_id}
                        label="Gamme"
                        onChange={handleChange}
                      >
                        {paramsData.gammes &&
                          paramsData.gammes.map((item: any) => (
                            <MenuItem
                              key={item.gamme_uuid}
                              value={item.gamme_id}
                            >
                              {item.gamme_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl className="text-field">
                      <InputLabel id="matiere-label">Matière</InputLabel>
                      <Select
                        labelId="matiere-label"
                        className=" select"
                        defaultValue={product.material_id}
                        {...register("material", { required: true })}
                        error={!!errors.material}
                        label="Matière"
                        onChange={handleChange}
                      >
                        {paramsData.materials &&
                          paramsData.materials.map((item: any) => (
                            <MenuItem
                              key={item.material_uuid}
                              value={item.material_id}
                            >
                              {item.material_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl className="text-field">
                      <InputLabel id="style-label">Style</InputLabel>
                      <Select
                        labelId="style-label"
                        className=" select"
                        label="Style"
                        defaultValue={product.style_id}
                        {...register("style", { required: true })}
                        error={!!errors.style}
                        onChange={handleChange}
                      >
                        {paramsData.styles &&
                          paramsData.styles.map((item: any) => (
                            <MenuItem
                              key={item.style_uuid}
                              value={item.style_id}
                            >
                              {item.style_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl className="text-field">
                      <InputLabel id="montage-label">Montage</InputLabel>
                      <Select
                        labelId="montage-label"
                        className=" select"
                        label="Montage"
                        defaultValue={product.assembly_id}
                        {...register("assembly", { required: true })}
                        error={!!errors.assembly}
                        onChange={handleChange}
                      >
                        {paramsData.assemblies &&
                          paramsData.assemblies.map((item: any) => (
                            <MenuItem
                              key={item.assembly_uuid}
                              value={item.assembly_id}
                            >
                              {item.assembly_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl className="text-field">
                      <InputLabel id="forme-label">Forme</InputLabel>
                      <Select
                        labelId="forme-label"
                        className=" select"
                        label="Forme"
                        defaultValue={product.form_id}
                        {...register("form", { required: true })}
                        error={!!errors.form}
                        onChange={handleChange}
                      >
                        {paramsData.forms &&
                          paramsData.forms.map((item: any) => (
                            <MenuItem key={item.form_uuid} value={item.form_id}>
                              {item.form_name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel id="radio-sexe">Genre</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="radio-sexe"
                        defaultValue={product?.wearer_id}
                        name="radio-buttons-group"
                      >
                        {paramsData.wearers &&
                          paramsData.wearers.map((item: any) => (
                            <FormControlLabel
                              value={item.wearer_uuid}
                              control={
                                <Radio
                                
                                  {...register("wearer", { required: true })}
                                  value={item.wearer_id}
                                />
                              }
                              label={item.wearer_name}
                            />
                          ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Stack>
              </div>
            </div>
          )}
          {activeStep === 2 && (
            <div className="step">
              <div className="step">
                <div className="step-header">
                  <h2>Féliciations !</h2>
                  <div className="subtitle">{t("products.verify_data")}</div>
                </div>

                <Stack direction="row" spacing={2}>
                  <div className="uploader">
                    <div className="zone">
                        {selectedImages.map((image: any, index: number) => (
                        (image.main==1 && <div className="img flex" key={index}>
                          <AuthenticatedImage url={"/product/image/" + image.pic_path}  width="50px" />
                          { image.main==1 && ( <MI.OneK  />)}
                        </div>)
                      ))}
                    </div>
                    <div className="row gap-8 img-preview">
                  {selectedImages.map((image: any, index: number) => (
                        <div className="img flex" key={index}>
                          <AuthenticatedImage url={"/product/image/" + image.pic_path}  width="50px" />
                          { image.main==1 && ( <MI.OneK  />)}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <Stack className="resume" direction="row" spacing={2}>
                      <div className="item">
                        <div className="label">Description</div>
                        <div className="val">{getValues("description")}</div>
                      </div>
                    </Stack>
                    <Stack className="resume" direction="row" spacing={2}>
                      <div className="item">
                        <div className="label">Référence</div>
                        <div className="val">{getValues("reference")}</div>
                      </div>
                      <div className="item">
                        <div className="label">Modèle</div>
                        <div className="val">{getValues("model")}</div>
                      </div>
                      <div className="item">
                        <div className="label">Prix d'achat</div>
                        <div className="val">{getValues("prix_achat")} DH</div>
                      </div>
                    </Stack>
                    <Stack className="resume" direction="row" spacing={2}>
                      <div className="item">
                        <div className="label">Prix de vente</div>
                        <div className="val">{getValues("prix_vente")} DH</div>
                      </div>
                      <div className="item">
                        <div className="label">Prix conseillé</div>
                        <div className="val">
                          {getValues("prix_conseille")} DH
                        </div>
                      </div>
                      <div className="item">
                        <div className="label">Delai de livraison</div>
                        <div className="val">
                          {getValues("delai_livraison")} Jours
                        </div>
                      </div>
                    </Stack>
                    <Stack className="resume" direction="row" spacing={2}>
                      <div className="item">
                        <div className="label">Largeur </div>
                        <div className="val">{getValues("width")} mm</div>
                      </div>
                      <div className="item">
                        <div className="label">Hauteur </div>
                        <div className="val">{getValues("height")} mm</div>
                      </div>
                      <div className="item">
                        <div className="label">Pont </div>
                        <div className="val">{getValues("bridge")} mm</div>
                      </div>
                      <div className="item">
                        <div className="label">Branche </div>
                        <div className="val">{getValues("length")} mm</div>
                      </div>
                    </Stack>
                    <Stack className="resume" direction="row" spacing={2}>
                      <div className="item">
                        <div className="label">Gamme</div>
                        <div className="val">
                          {paramsData.gammes &&
                            paramsData.gammes.map((item: any) =>
                              item.gamme_id == getValues("gamme")
                                ? item.gamme_name
                                : "",
                            )}
                        </div>
                      </div>
                      <div className="item">
                        <div className="label">Matière</div>
                        <div className="val">
                          {paramsData.materials &&
                            paramsData.materials.map((item: any) =>
                              item.material_id == getValues("style")
                                ? item.material_name
                                : "",
                            )}
                        </div>
                      </div>
                      <div className="item">
                        <div className="label">Style</div>
                        <div className="val">
                          {paramsData.styles &&
                            paramsData.styles.map((item: any) =>
                              item.style_id == getValues("style")
                                ? item.style_name
                                : "",
                            )}
                        </div>
                      </div>
                      <div className="item">
                        <div className="label">Montage</div>
                        <div className="val">
                          {paramsData.assemblies &&
                            paramsData.assemblies.map((item: any) =>
                              item.assembly_id == getValues("assembly")
                                ? item.assembly_name
                                : "",
                            )}{" "}
                        </div>
                      </div>
                    </Stack>
                    <Stack className="resume" direction="row" spacing={2}>
                      <div className="item">
                        <div className="label">Sexe</div>
                        <div className="val">
                          {paramsData.wearers &&
                            paramsData.wearers.map((item: any) =>
                              item.wearer_id == getValues("wearer")
                                ? item.wearer_name
                                : "",
                            )}{" "}
                        </div>
                      </div>
                      <div className="item">
                        <div className="label">Stock</div>
                        <div className="val">
                          {getValues("disponibility")
                            ? t("common.available")
                            : t("common.unavailable")}
                        </div>
                      </div>
                      <div className="item">
                        <div className="label">En vedette</div>
                        <div className="val">
                          {getValues("featured")
                            ? t("common.yes")
                            : t("common.no")}
                        </div>
                      </div>
                    </Stack>
                  </div>
                </Stack>
              </div>
            </div>
          )}
          {activeStep === 3 && <div className="step">Final Step</div>}
        </form>
      </DialogContent>

      <Stack direction="row" className="dialog-actions">
        <div className="row flex gap-16">
          <Stepper className="stepper" activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}></Box>
          )}
        </div>

        <div className="row btn-actions gap-24">
           {activeStep < steps.length - 1 &&(
          <Button
              variant="outlined"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              {t("common.previous")}
            </Button>
           )}
          
          <Button variant="contained" onClick={handleNext}>
            {activeStep === steps.length - 1
              ? t("common.end")
              : t("common.next")}
          </Button>
        </div>
      </Stack>
    </Dialog>
    <LoadingDialog open={working} />
    </>
  );
}

export default EditProductModal;
