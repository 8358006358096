import React from 'react';
import { Dialog, DialogContent, CircularProgress } from '@mui/material';

const LoadingDialog = ({ open } : {open : boolean}) => {
  return (
    <Dialog open={open}>
      <DialogContent>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;