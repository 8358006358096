import React from "react";
import * as MI from "@mui/icons-material";

function renderStars(rating: number) {
  const stars = [];
  const fullStarCount = Math.floor(rating);
  const remainingStars = rating - fullStarCount;
  let i;

  for (i = 0; i < fullStarCount; i++) {
    stars.push(<MI.Star key={i} sx={{ color: "#FFD233", width: 16 }} />);
  }

  if (remainingStars > 0) {
    stars.push(<MI.StarHalf key={i} sx={{ color: "#FFD233", width: 16 }} />);
    i++;
  }

  // Add empty stars to complete 5-star rating
  while (i < 5) {
    stars.push(<MI.StarBorder key={i} sx={{ color: "#DDDDDD", width: 16 }} />);
    i++;
  }

  return stars;
}
export default renderStars;
