import React from "react";
import "./styles.css";
import Sidebar from "../../components/Sidebar";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { styled } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts/hooks";
import * as I from "../../components/Icons";
import * as MI from "@mui/icons-material";
import { t } from "i18next";

const data = [
  { label: "Group A", value: 400 },
  { label: "Group B", value: 300 },
  { label: "Group C", value: 300 },
  { label: "Group D", value: 200 },
];

const palette = ["#962DFF", "#b45ac4", "#4A3AFF", "#ffc107"];

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 20,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();

  return (
    <>
      <StyledText className="center-label" x={left + width / 2} y={height / 3}>
        {children}
      </StyledText>
      <StyledText className="total" x={left + width / 2} y={height / 3 + 24}>
        Total
      </StyledText>
    </>
  );
}

export default function Dashboard() {
  return (
    <div className="page">
      <div className="row">
        <Sidebar />
        <div className="content flex">
          <h1>{t("dashboard.dashboard")}</h1>
          <div className="column gap-24">
            <div className="row gap-24">
              <div className="bloc flex">
                <div className="row between">
                  <div className="subtitle">Ce mois-ci</div>
                  <div className="percent up">
                    <span>17%</span>
                    <span>
                      <MI.ArrowDropDown />
                    </span>
                  </div>
                </div>
                <h2>En attente</h2>

                <div className="graph">
                  <div className="kpi flex">08</div>
                  <I.Line color="#04CE00" />
                </div>
              </div>
              <div className="bloc flex">
                <div className="row between">
                  <div className="subtitle">Ce mois-ci</div>
                  <div className="percent down">
                    <span>17%</span>
                    <span>
                      <MI.ArrowDropDown />
                    </span>
                  </div>
                </div>
                <h2>Ce mois-ci</h2>
                <div className="graph">
                  <div className="kpi flex">25</div>
                  <I.Line color="#FF718B" />
                </div>
              </div>
              <div className="bloc flex">
                <div className="row between">
                  <div className="subtitle">Ce mois-ci</div>
                  <div className="percent down">
                    <span>17%</span>
                    <span>
                      <MI.ArrowDropUp />
                    </span>
                  </div>
                </div>
                <h2>Terminée</h2>
                <div className="graph">
                  <div className="kpi flex">366</div>
                  <I.Line color="#04CE00" />
                </div>
              </div>
              <div className="bloc flex">
                <div className="row between">
                  <div className="subtitle">Ce mois-ci</div>
                  <div className="percent down">
                    <span>17%</span>
                    <span>
                      <MI.ArrowDropUp />
                    </span>
                  </div>
                </div>
                <h2>Total</h2>
                <div className="graph">
                  <div className="kpi flex">18</div>
                  <I.Line color="#04CE00" />
                </div>
              </div>
            </div>
            <div className="row gap-24">
              <div className="bloc flex">
                <div className="header">
                  <div className="subtitle">Ce mois-ci</div>
                  <h2>Ventes par Marque</h2>
                </div>
                <div className="chart">
                  <div className="time-selector">
                    <div className="element">Jour</div>
                    <div className="element">Semaine</div>
                    <div className="element selected">Mois</div>
                  </div>
                  <PieChart
                    colors={palette}
                    series={[
                      {
                        cy: 100,
                        paddingAngle: 5,
                        innerRadius: 60,
                        outerRadius: 100,
                        cornerRadius: 5,
                        startAngle: 0,
                        endAngle: 360,
                        arcLabel: (item) => `${item.value}`,
                        arcLabelMinAngle: 45,
                        data,
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: "white",
                        fontWeight: "bold",
                      },
                    }}
                    slotProps={{
                      legend: {
                        direction: "column",
                        position: { vertical: "bottom", horizontal: "left" },
                        padding: { bottom: 0, left: 0 },
                      },
                    }}
                    margin={{ right: 0, top: 0 }}
                    height={300}
                  >
                    <PieCenterLabel>1.05</PieCenterLabel>
                  </PieChart>
                </div>
              </div>

              <div className="bloc flex-2">
                <div className="header">
                  <div className="subtitle">Ce mois-ci</div>
                  <h2>Ventes par Marque</h2>
                </div>
                <div className="chart">
                  <div className="time-selector">
                    <div className="element">Jour</div>
                    <div className="element">Semaine</div>
                    <div className="element selected">Mois</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row gap-24">
              <div className="bloc flex-3">
                <div className="header">
                  <div className="row">
                    <div className="flex">
                      <div className="subtitle">Ce mois-ci</div>
                      <h2>Ventes par Marque</h2>
                    </div>
                    <div className="time-selector">
                      <div className="element">Jour</div>
                      <div className="element">Semaine</div>
                      <div className="element selected">Mois</div>
                    </div>
                  </div>
                </div>
                <div className="chart"></div>
              </div>

              <div className="bloc flex">
                <div className="header">
                  <div className="subtitle">Ce mois-ci</div>
                  <h2>Ventes par Marque</h2>
                </div>
                <div className="chart"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
