import { Navigate } from "react-router-dom";
import config from "../config.json";
import { useState } from "react";
import SplashScreen from "../pages/splash";
import axiosInstance from "./axsioInstance";

const UnProtected = ({ children }: { children: any }) => {
  const [logged, setLogged] = useState(false);
  const [isDone, setDone] = useState(false);
  isLoggedIn().then((value) => {
    setLogged(value);
    setDone(true);
  });
  if (isDone) {
    if (!logged) {
      return children;
    } else {
      return <Navigate to="/dashboard" />;
    }
  } else return <SplashScreen />;
};

async function isLoggedIn() {
  return axiosInstance
    .get(config.URLS.logged_in, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(
      (response) => {
        return response.data.loggedIn ?? false;
      },
      (error) => {
        console.error(error);
        return false;
      },
    );
}
export default UnProtected;
