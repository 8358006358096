

import {Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as MI from '@mui/icons-material';
import * as I from '../Icons';
import { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axsioInstance';
import ratingStars from './ratingStars';
import { useTranslation } from 'react-i18next';
import config from '../../config.json'
import AuthenticatedImage from '../Common/authImg';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import toast from 'react-hot-toast';
import EditProductModal from './editProduct';
import LoadingDialog from '../Common/loadingDialog';

   
 function ProductPreview({ productData,paramsData ,setRefresh,setProduct}: { productData: any,paramsData:any,setRefresh: React.Dispatch<React.SetStateAction<boolean>>,setProduct: React.Dispatch<React.SetStateAction<any>> } ) {
  
    const [loading,setLoading] = useState(false);
    const [open_confirm,setOpenConfirm] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
  const [working, setWorking] = useState(false);
    
    const {t} = useTranslation('common');


    return (
        <>
        
      <EditProductModal paramsData={paramsData} product={productData} open={editOpen} setRefresh={setRefresh} setOpen={setEditOpen} />
      <Dialog open={open_confirm} onClose={()=>{setOpenConfirm(false)}}>
        <DialogTitle>{t('common.confirmation')}</DialogTitle>
        <DialogContent>
          <p>{t('common.confirm_delete')}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpenConfirm(false)}} color="primary">
            {t('common.no')}
          </Button>
          <Button onClick={async ()=>{
         try{
                setWorking(true);
                const response  = await  axiosInstance
                .post(config.URLS.delete_product, {
                    "product" : productData?.id
                }, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                });
               
                    if(response.data.success)
                    {
                        toast.success(t(response.data.message));
                        setRefresh(true);
                        setOpenConfirm(false);
                        setProduct(null);
                    }else{
                    toast.error(t(response.data.message));
                    }
                
                
            }catch(error) {
                    
                    toast.error(t("common.request_error"));
            }
            finally{
                setWorking(false);
            }
          }} color="primary" autoFocus>
               {t('common.yes')}
          </Button>
        </DialogActions>
      </Dialog>
        <div className="bloc phone-preview">
            <div className="close-details-container" onClick={()=>{setProduct(null)}}><I.Close  /></div>

            {loading ? (
                    <>
                    <div className="carousel-preview">
                    <Skeleton height={300} width={'100%'} />
                    </div>
                    <div className="wrapper">
                        <Skeleton count={1} />
                        
                        <div className="row rating-container">
                            <div className="flex">
                                <div className="subtitle"><Skeleton /></div>
                                <div className="ref"><Skeleton /></div>
                            </div>
                            { ratingStars(0)}
                        </div>
                        <div className="row center social-container">
                            <div className="social flex">
                                <I.Heart />
                                <I.Share />
                            </div>
                            <div className="price"><Skeleton /></div>
                        </div>
                        <div className="desc"><Skeleton /></div>
                        <Skeleton />
                        <Skeleton  count={8} />
                    </div>
                    
                    </>
                ): (
                    <>
                        <div className="carousel-preview">
                            <AuthenticatedImage width="100%" url={"/product/image/" + productData.pic} />
                        </div>
                        <div className="wrapper">
                        <h1>{productData.product_model}</h1>
                        <div className="row rating-container">
                            <div className="flex">
                                <div className="subtitle">{productData.vendor?.vendor_name}</div>
                                <div className="ref">{productData.product_reference}</div>
                            </div>
                           { ratingStars(productData.rating)}
                        </div>
                        <div className="row center social-container">
                            <div className="social flex">
                                <I.Heart />
                                <I.Share />
                            </div>
                            <div className="price">{productData.prix_vente} DH</div>
                        </div>
                        <div className="desc">{productData.description}</div>
                        <h2>dimensions</h2>
                        <div className="row gap-8">
                            <div className="flex box">
                            <div className="metrics">{productData.height} mm</div>
                            <I.GlassH />
                            <div className="metrics">Hauteur</div>
                            </div>
                            <div className="flex box">
                            <div className="metrics">{productData.width} mm</div>
                            <I.GlassW />
                            <div className="metrics">{t('products.width')}</div>
                            </div>
                            <div className="flex box">
                            <div className="metrics">{productData.bridge} mm</div>
                            <I.GlassBr />
                            <div className="metrics">{t('products.bridge')}</div>
                            </div>
                            <div className="flex box">
                            <div className="metrics">{productData.length} mm</div>
                            <I.GlassB />
                            <div className="metrics">{t('products.length')}</div>
                            </div>
                        </div>
                        <h2>Caractéristiques</h2>
                        <div className="items">
                            <div className="item">
                            <div className="label">Sexe</div>
                            <div className="label-right">{productData.wearer?.wearer_name}</div>
                            </div>
                            <div className="item">
                            <div className="label">Matériaux</div>
                            <div className="label-right">{productData.material?.material_name}</div>
                            </div>
                            <div className="item">
                            <div className="label">Forme</div>
                            <div className="label-right">{productData.form?.form_name}</div>
                            </div>
                            <div className="item">
                            <div className="label">Montage</div>
                            <div className="label-right">{productData.assembly?.assembly_name}</div>
                            </div>
                            <div className="item">
                            <div className="label">Style</div>
                            <div className="label-right">{productData.style?.style_name}</div>
                            </div>
                        </div>
                        <div className="row btn-actions gap-8">
                            <Button variant="contained" onClick={()=>{setEditOpen(true)}} startIcon={<MI.Edit />}>{t('products.edit_product')}</Button>
                            <Button
                                variant="outlined"
                                startIcon={<MI.Close color="error" />}
                                onClick={()=>{setOpenConfirm(true)}}
                            >
                                {t('products.delete_product')}
                            </Button>
                        </div>
                       </div>
                    </>
                )}
            </div>
                  <LoadingDialog open={working} />
            </>
        
    );
}

export default ProductPreview;