import { Navigate } from "react-router-dom";
import config from "../config.json";
import { useState, useEffect } from "react";
import SplashScreen from "../pages/splash";
import axiosInstance from "./axsioInstance";
import { useGlobalContext } from "./globalContext";

const Protected = ({ children }: { children: any }) => {
  const [logged, setLogged] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { user, setUser } = useGlobalContext();

  useEffect(() => {
    isLoggedIn();
  }, []);

  async function isLoggedIn() {
    console.log('running isloged');
    try {
      const response = await axiosInstance.get(config.URLS.logged_in);
      if (response.data.loggedIn) {
        setUser(response.data.data);
        setLogged(true);
      } else {
        setLogged(false);
      }
    } catch (error) {

      setLogged(false);
    } finally {
      setLoading(false);
    }
  }

  if (isLoading) {
    return <SplashScreen />;
  }

  return logged ? children : <Navigate to="/login" />;
};

export default Protected;