import  { useEffect, useState } from 'react';
import './styles.css';
import Sidebar from '../../components/Sidebar';
import {Box, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import * as MI from '@mui/icons-material';
import * as I from '../../components/Icons';
import { DataGrid, GridActionsCellItem, GridCallbackDetails, GridCellParams, GridColDef, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import axiosInstance from '../../utils/axsioInstance';
import config from '../../config.json';
import NoData from "../../animations/no-data.json";
import Lottie from 'lottie-react';
import OrderDetails from '../../components/Orders/orderDetails';
import OrderStatus from '../../components/Orders/orderStatus';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import debounce from "lodash/debounce";
import LoadingDialog from '../../components/Common/loadingDialog';






export default function Orders() {
const { t } = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [possibleStates, setPossibleStates] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 5 });
  const [refresh, setRefresh] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedOrder, setselectedOrder] = useState(null);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [paramsData, setParamsData] = useState<any>(null);
  const [working, setWorking] = useState(false);

const columns: GridColDef[] = [
  {
    field: "created_at",
    headerName: "Date commande",
    flex: 1.1,
    cellClassName: "centered-content",
    renderCell: (params: GridCellParams) => (
       <Moment format='DD/M/YYYY HH:mm' >{params.row.created_at}</Moment>
    )
   
  },
  {
    field: "tracking_id",
    headerName: "Réference",
    flex: 1,
    cellClassName: "centered-content",
  },
  {
    field: "magasin",
    headerName: "Opticien",
    flex: 1,
    cellClassName: "centered-content",
    renderCell: (params: GridCellParams) => (
      <div className="row center gap-16">
        <div className="rating column gap-8 flex">
          <div className="item-name">{params.row.magasin}</div>
        </div>
      </div>
    ),
  },
  {
    field: "tel",
    headerName: "Opticien",
    flex: 1,
    cellClassName: "centered-content"
  },

  {
    field: "city",
    renderCell: (params: GridCellParams) => (
      <div>{ params.row.city.city_name}</div>
    ),
    headerName: "Ville",
    flex: 1,
    cellClassName: "centered-content",
  },

  {
    field: "order_state",
    renderCell: (params: GridCellParams) => (
      <div>{params.row.order_state==config.STATES.CANCELED?<HighlightOffIcon color='error'  fontSize={'inherit'}  />:(params.row.order_state==config.STATES.FINISHED?<CheckCircleOutlineIcon color='success' fontSize={'inherit'}  />:<HourglassBottomIcon color='primary' fontSize={'inherit'}  />)} {params.row.state.state_name}</div>
    ),
    headerName: "Etat",
    flex: 1.1,
    cellClassName: "centered-content",
  },

  {
    field: "total",
    headerName: "Total",
    flex: 1,
    cellClassName: "centered-content",
     renderCell: (params: GridCellParams) => (
      <div>{params.row.total} DH</div>
    ),
  },
  {
    field: "actions",
    headerName: "Actions",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    flex: 1,
    cellClassName: "centered-content",
    getActions: (params: any) => [
      <GridActionsCellItem
        icon={<MI.Visibility />}
        label="Voire"
        onClick={() => handleEdit(params.row.id)}
        showInMenu
      />,
    ],
    renderCell: (params) => (
      <div className="row gap-16">
        <IconButton aria-label="Voire" onClick={() => handleEdit(params.row)}>
          <MI.Visibility color="primary" />
        </IconButton>
      </div>
    ),
    //valueGetter: (value:any, row:any) => `${row.marque || ''} ${row.article || ''}`,
    //valueGetter: (value:any, row:any) => <MI.Edit/>,
  },
];
function handleEdit(element: any): void {
  setselectedOrder(element);
}

  useEffect(() => {
    const fetchData = async () => {
       if(!refresh)
          return;
      try {
         const formData = new FormData();
         formData.append("search_term",search);
         formData.append("city",city);
         formData.append("status",status);
        const response = await axiosInstance.post(config.URLS.list_orders,formData);
        setRows(response.data.data);
        setPagination({
          page: response.data.current_page,
          pageSize: response.data.per_page,
        });
        setRefresh(false);
        setWorking(false);
        setDataLoaded(true);
      } catch (error) {
        console.error("Error fetching data:", error);

      }
    };

    fetchData();
    
  }, [refresh]);

  useEffect(() => {
  
    const fetchstats = async () => {
    
          try {
          axiosInstance
          .get(config.URLS.order_params)
          .then((response) => {

            setParamsData(response.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        const resp = await axiosInstance.get(config.URLS.possible_states);
        console.log(resp.data);
        setPossibleStates(resp.data);
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    };
    fetchstats();
    
  }, []);

  
  const debouncedSearch = debounce((searchValue) => {
    setSearch(searchValue); 
    setWorking(true);setRefresh(true);
  }, 1000);

  return (
  <div className="orders page">
    <div className="row">
      <Sidebar isItemSelected={selectedOrder != null} />
      <div className="content flex">
        <div className="row main-header">
          <div className="page-title row gap-16 center">
            <I.Orders/>
            <h1>Mes commandes</h1>
          </div>
            <FormControl size="small" sx={{ display: "flex", alignItems: "flex-end" }}>
              <Input
                className="search-input"
                disableUnderline
                startAdornment={
                    <InputAdornment position="start">
                      <I.Search />
                    </InputAdornment>
                  }
                placeholder={t("products.search")}
                            onChange={(event)=>{ debouncedSearch(event.target.value);}}

              />
            </FormControl>
        
          
        </div>
        <div className="divider-56"></div>
        
        <div className="column gap-24">
          <div className="row gap-24">
            <div className="flow-container">
               {selectedOrder && (
                    <OrderStatus possibleStates={possibleStates} orderData={selectedOrder} setRefresh={setRefresh} />)}


                   
              <div className="data-grid-container bloc flex">
                <div className="filter-bar">
         
                        <FormControl variant="filled" sx={{ m: 1, minWidth: 140 }}>
                          <InputLabel id="lbl1">Ville</InputLabel>
                          <Select
                          className="select"
                            id="select-filled"
                            size="small"
                            disableUnderline
                              onChange={(event)=>{setCity(event.target.value);setRefresh(true);setWorking(true);}}

                          >
                            <MenuItem value="">
                              <em>Effacer</em>
                            </MenuItem>
                                 {
                                paramsData !=null &&
                                paramsData.cities.map((option: any, index: number) => (
                                <MenuItem key={option.city_id} value={option.city_id}>
                                  {option.city_name}
                                </MenuItem>
                                    ))}
                          </Select>
                        </FormControl>
                        <FormControl variant="filled" sx={{ m: 1, minWidth: 140 }}>
                          <InputLabel id="lbl1">Etat</InputLabel>
                          <Select
                          className="select"
                            id="select-filled"
                            size="small"
                            disableUnderline
                            onChange={(event)=>{setStatus(event.target.value);setRefresh(true);setWorking(true);}}
                          >
                            <MenuItem value="">
                              <em>Effacer</em>
                            </MenuItem>
                               {
                                paramsData !=null &&
                                paramsData.status.map((option: any, index: number) => (
                                <MenuItem key={option.state_id} value={option.state_id}>
                                  {option.state_name}
                                </MenuItem>
          ))}
                               
                          </Select>
                        </FormControl>
                       
                         

                  </div>
                      {(rows.length<=0)?
                  ( <div className="bloc no-data">
                      <Lottie animationData={NoData}  style={{ height: 160 }} />
                      <h1>{ dataLoaded?t('orders.no_data'):t('common.loading')}</h1>
                    </div>):
               ( 
             
              <DataGrid
                getRowHeight={() => 'auto'}
                autosizeOnMount
                rows={rows}
                columns={columns}
                         onRowClick={(
                      params: GridRowParams,
                      event: MuiEvent,
                      details: GridCallbackDetails,
                    ) => {
                      setselectedOrder(params.row);
                    }}
                initialState={{
                  pagination: {
                    paginationModel: pagination,
                  },
                }}
                pageSizeOptions={[5, 10]}
              />
              
              )}
            </div>
            </div>
                {selectedOrder && (
            <OrderDetails orderData={selectedOrder} setOrderData={setselectedOrder} setRefresh={setRefresh}  />)}
            <LoadingDialog open={working} />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
