import { useState, useEffect } from "react";
import axiosInstance from "../../utils/axsioInstance";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import Moment from 'react-moment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import config from '../../config.json';
import { Stepper, Step, StepLabel, StepIcon, StepIconProps } from "@mui/material";

function OrderStatus({ orderData ,setRefresh,possibleStates}: { orderData: any,setRefresh: React.Dispatch<React.SetStateAction<boolean>>,possibleStates : any[]}) {

    const CanceledStepIcon = (props: StepIconProps) => {
    return <StepIcon {...props} icon={<HighlightOffIcon color={"error"} />} />;
  };
    const  CheckedStepIcon = (props: StepIconProps) => {
    return <StepIcon {...props} icon={<CheckCircleOutlineIcon color={"success"} />} />;
  };
    const waitStepIcon = (props: StepIconProps) => {
    return <StepIcon {...props} icon={<HourglassBottomIcon  color={"primary"}  />} />;
  };
const activeStep = possibleStates.findIndex((state) => state.state_id === orderData.max_status) + (orderData?.canceled_at!=null?1:0);
 const remainingStates = possibleStates.filter((state) => !orderData.actions.some((action: any) => action.state.state_id === state.state_id));
 
  return ( <div className="bloc flow">
                <h2>Suivi de la commande</h2>
                
              <Stepper className="stepper" activeStep={activeStep} alternativeLabel>
                      {orderData?.actions.map((action: any, index: number) => (
                        action.state.state_id !== config.STATES.CANCELED && (
                       <Step key={action.state.state_id} >
                          <StepLabel StepIconComponent={CheckedStepIcon}>
                            <div className="event-date"><Moment format={'DD/MM/YYYY HH:mm'}>{action?.created_at}</Moment></div>
                            {action?.state?.state_name} <small>{action?.vuser?.lname} </small>
                          </StepLabel>
                        </Step>)
                      ))}
                      {orderData.canceled_at!=null && 
                      
                         <Step  key={orderData?.state.state_id}  >
                          <StepLabel StepIconComponent={CanceledStepIcon}>
                            <div className="event-date"><Moment format={'DD/MM/YYYY HH:mm'}>{orderData?.canceled_at}</Moment></div>
                            {orderData?.state?.state_name} <small>{orderData?.vuser?.lname}   
                                                                  <Tooltip title={orderData?.cancel_motif}  placement="bottom">
                                                                  <IconButton>
                                                                    <InfoIcon />
                                                                  </IconButton>
                                                                </Tooltip></small>
                          </StepLabel>
                        </Step>}

                      {remainingStates.map((state: any) => (
                        <Step key={state.state_id}>
                          <StepLabel  StepIconComponent={waitStepIcon} >{state.state_name}</StepLabel>
                        </Step>
                      ))}
                    </Stepper></div>);
}

export default OrderStatus;
